<template>
    <div>
        <a-card style="background-color: #fff;">
            <div :class="advanced ? 'search' : null">
                <a-form layout="horizontal">
                    <div :class="advanced ? null : 'fold'">
                        <a-row>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="栏目" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.classifyType" placeholder="请选择栏目"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in menuList" :key="item.id"
                                            :label="item.name">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="分销商" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.distributorCode" placeholder="请选择分销商"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in codeList" :key="item.id"
                                            :label="item.name" @click="selectCode(item.id)">{{ item.name
                                            }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="一级分类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.firstClassifyId" placeholder="请选择一级分类"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in firstList" :key="item.id"
                                            :label="item.name" @click="pIdChange(item.id, 0)">{{ item.name
                                            }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="二级分类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.secondClassifyId" placeholder="请选择二级分类"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in secondList" :key="item.id"
                                            :label="item.name" @click="pIdChange(item.id, 1)">{{ item.name
                                            }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="三级分类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.tertiaryClassifyId" placeholder="请选择三级分类"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in tertiaryList" :key="item.id"
                                            :label="item.name" @click="pIdChange(item.pId, 2)">{{ item.name
                                            }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="平台状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.status" placeholder="请选择平台状态"
                                        option-label-prop="label">
                                        <a-select-option :value="1" label="上架">上架</a-select-option>
                                        <a-select-option :value="2" label="下架">下架</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="三方状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.state" placeholder="请选择三方状态"
                                        option-label-prop="label">
                                        <a-select-option :value="1" label="上架">上架</a-select-option>
                                        <a-select-option :value="2" label="下架">下架</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>


                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="入库时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-range-picker :value="dateValue"
                                        :ranges="{ '今天': [moment(), moment()], '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] }"
                                        @change="dateChange" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="关键词" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-input v-model="queryData.name" placeholder="请输入产品短称/商品关键词" />
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="平台售价" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-row>
                                        <a-col :span="11">
                                            <a-input v-model="queryData.plfPriceMin" placeholder="最小" />
                                        </a-col>
                                        <a-col :span="2" style="text-align:center">
                                            -
                                        </a-col>
                                        <a-col :span="11">
                                            <a-input v-model="queryData.plfPriceMax" placeholder="最大" />
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="评论" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-row>
                                        <a-col :span="11">
                                            <a-input v-model="queryData.commentMin" placeholder="最小" />
                                        </a-col>
                                        <a-col :span="2" style="text-align:center">
                                            -
                                        </a-col>
                                        <a-col :span="11">
                                            <a-input v-model="queryData.commentMax" placeholder="最大" />
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-col>


                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="京东SKU" :labelCol="{ span: 5 }"
                                    :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-input v-model="queryData.jdSku" placeholder="请输入京东SKU" />
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="标签" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.secondTag" placeholder="请选择标签"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in tagList" :key="item.id"
                                            :label="item.name">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24">
                                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    @click="search">查询</a-button>

                            </a-col>

                        </a-row>
                        <div style="margin-bottom: 10px;display:flex">
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="exportTask">导出</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="skuChange">批量录入SKU同步</a-button>
                            <div v-if="selectList.length > 0">
                                <a-popconfirm placement="topLeft" ok-text="是" cancel-text="否" @confirm="allChange(0)">
                                    <template slot="title">
                                        <p>您是否要批量设置人工筛选？</p>
                                    </template>
                                    <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                        :disabled="selectList.length == 0">批量设置人工筛选</a-button>
                                </a-popconfirm>
                            

                                <a-popconfirm placement="topLeft" ok-text="是" cancel-text="否" @confirm="allChange(1)">
                                    <template slot="title">
                                        <p>您是否要批量取消人工筛选？</p>
                                    </template>
                                    <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                        :disabled="selectList.length == 0">批量取消人工筛选</a-button>
                                </a-popconfirm>
                            </div>
                            <div v-else>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    :disabled="selectList.length == 0">批量设置人工筛选</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    :disabled="selectList.length == 0">批量取消人工筛选</a-button>
                             
                            </div>
                        </div>
                        <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;margin-bottom: 10px;">显示第 {{ total
                > 0 ? 1 : 0 }} 至 {{
                queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项

                            <span style="color:red;margin-left:20px">队列中等待同步总数量：{{ awaitNumber }}</span>
                        </div>
                    </div>
                </a-form>
            </div>

            <a-modal title="批量拉取SKU" :visible="skuOpen" :width="600" @ok="handleSku" @cancel="skuOpen = false;">
                <a-textarea placeholder="SKU英文逗号分隔，示例：100018205631,100017223948" v-model="skuStr"></a-textarea>
            </a-modal>

            <div>
                <a-table :columns="columns" bordered :dataSource="dataSource" :loading="tableLoading"
                    :row-selection="rowSelection"  :pagination="false"
                    :scroll="{ x: 1500 }" size="middle">
                    <div slot="createTime" slot-scope="text">{{ formatDate(text) }}</div>
                    <div slot="modifyTime" slot-scope="text">{{ formatDate(text.modifyTime) }}</div>

                    <div slot="status" slot-scope="text">
                        <span v-if="text == 1" style="color: #008000;">上架</span>
                        <span v-if="text == 2" style="color: #ff0000;">下架</span>
                    </div>
                    <div slot="state" slot-scope="text">
                        <span v-if="text == 1" style="color: #008000;">上架</span>
                        <span v-if="text == 2" style="color: #ff0000;">下架</span>
                    </div>
                    <div slot="imagePath" slot-scope="text" style="width:100px;height:100px;">
                        <img style="max-width: 100px;max-height:100px;" :src="text" alt="">
                    </div>
                    <div slot="h5Url" slot-scope="text">
                        <a :href="text" target="_blank" v-if="text">查看</a>
                        <!-- <a @click="openUrl(text)" v-if="text">查看</a> -->
                    </div>

                    <div slot="plfPrice" slot-scope="text,record">
                        {{ text ? text : record.plfPriceStr}}
                    </div>

                    

                    <div slot="action" slot-scope="record">
                        <a @click="asyncGoods(record)">同步商品</a>
                        <a @click="allOneChange(record.id,0)" v-if="!record.lableName" style="margin-left:10px">设置人工筛选</a>
                        <a @click="allOneChange(record.id,1)" v-else style="margin-left:10px">取消人工筛选</a>
                    </div>
                </a-table>
                <div class="page-box">
                    <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                        :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                        show-size-changer />
                </div>
            </div>
        </a-card>

        <!-- <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
            <a-empty :description="false" />
            无权限查看
        </div> -->
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { publicMixin } from '@/mixin/public';
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 70 },
    {
        title: '品牌名称',
        dataIndex: 'name',
        width: 260,
        align: 'center'
    },
    {
        title: '产品图片',
        dataIndex: 'imagePath',
        scopedSlots: { customRender: 'imagePath' },
        width: 120,
        align: 'center'
    },
    // {
    //     title: 'SKUID',
    //     dataIndex: 'id',
    //     width: 120,
    //     // width: '30%',
    //     align: 'center'
    // },
    {
        title: '京东SKU',
        dataIndex: 'jdSku',
        width: 100,
        // width: '30%',
        align: 'center'
    },
    {
        title: '平台售价',
        dataIndex: 'plfPrice',
        scopedSlots: { customRender: 'plfPrice' },
        width: 100,
        // width: '30%',
        align: 'center'
    },
    {
        title: 'H5链接',
        dataIndex: 'h5Url',
        scopedSlots: { customRender: 'h5Url' },
        width: 100,
        // width: '30%',
        align: 'center'
    },
    
    {
        title: '评论数',
        dataIndex: 'commentOriginal',
        width: 100,
        // width: '30%',
        align: 'center'
    },
    {
        title: '商品标签',
        dataIndex: 'lableName',
        width: 100,
        scopedSlots: { customRender: 'lableName' },
        // width: '30%',
        align: 'center'
    },
    {
        title: '商品来源',
        dataIndex: 'sourceName',
        width: 100,
        // width: '30%',
        align: 'center'
    },

    {
        title: '小程序菜单-分销商-一级分类-二级分类-三级分类',
        dataIndex: 'classifyName',
        width: 300,
        align: 'center'
    },
    {
        title: '优加状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
        width: 100,
        // width: '10%',
        align: 'center'
    },
    {
        title: '三方状态',
        dataIndex: 'state',
        width: 100,

        scopedSlots: { customRender: 'state' },
        // width: '10%',
        align: 'center'
    },
    {
        title: '入库时间',
        dataIndex: 'createTime',
        width: 160,
        scopedSlots: { customRender: 'createTime' },
        // width: '15%',
        align: 'center'
    },
    {
        title: '最后更新时间',
        scopedSlots: { customRender: 'modifyTime' },
        width: 160,
        // width: '30%',
        align: 'center'
    },
    {
        title: '操作',
        scopedSlots: { customRender: 'action' },
        fixed: 'right',
        align: 'center',
        width: 180
    }
]

export default {
    name: 'QueryList',
    mixins: [publicMixin],
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            expandedRowKeys: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            menuList: [{ id: 1, name: '商城' }, { id: 3, name: '蛋糕' }, { id: 4, name: '礼包' }, { id: 5, name: '生活' }, { id: 6, name: '演出' }, { id: 7, name: '图书' }],
            codeList: [{ id: '20220601001', name: '优加惠品' }, { id: '20221212001', name: '好乐滋' }],
            firstList: [],//一级分类
            secondList: [],//二级分类
            tertiaryList: [],//三级分类
            pId: 0,
            tagList: [{ id: 'FmaIKwYgSF', name: '人工筛选' }],
            skuOpen: false,
            skuStr: "",

            awaitNumber: 0,
            selectList: [],
            selectedRowKeys:[]
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        },
        rowSelection() {
            return {
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys; // Update selected row keys
                    this.selectList = selectedRows;
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                onSelect: (record, selected, selectedRows) => {
                    console.log(record, selected, selectedRows);
                    this.selectList = selectedRows;
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    console.log(selected, selectedRows, changeRows);
                    this.selectList = selectedRows;
                },
            }
        }
    },
    watch: {
        'queryData.classifyType': function (newVal, oldVal) {
            if (newVal != oldVal) {
                delete this.queryData.distributorCode;
                delete this.queryData.firstClassifyId
                delete this.queryData.secondClassifyId;
                delete this.queryData.tertiaryClassifyId;
                this.firstList = []
                this.secondList = []
                this.tertiaryList = [];
                this.pId = 0;
            }
        },
        'queryData.distributorCode': function (newVal, oldVal) {
            if (newVal != oldVal) {
                delete this.queryData.firstClassifyId
                delete this.queryData.secondClassifyId;
                delete this.queryData.tertiaryClassifyId;
                this.firstList = []
                this.secondList = []
                this.tertiaryList = [];
                this.pId = 0;
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' + this.pId + '/' + newVal, 'get').then(res => {
                    this.firstList = res.data.data;
                })
            }
            if (newVal) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' + this.pId + '/' + newVal, 'get').then(res => {
                    this.firstList = res.data.data;
                })
            }
        },
        'queryData.firstClassifyId': function () {
            if (this.queryData.firstClassifyId) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' + this.pId + '/' + this.queryData.distributorCode, 'get').then(res => {
                    this.secondList = res.data.data;
                })
            }
        },
        'queryData.secondClassifyId': function () {
            if (this.queryData.secondClassifyId) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' + this.pId + '/' + this.queryData.distributorCode, 'get').then(res => {
                    this.tertiaryList = res.data.data;
                })
            }
        },
    },
    methods: {
        allChange(type) {
            let goodsList = []
            this.selectList.forEach(item => {
                goodsList.push(item.id)
            })
            if (type == 0) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/tag/batch', 'post', { goodsList, tagList: ["FmaIKwYgSF"] }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('提交成功！异步处理中')
                        this.getData()
                        this.selectList = []
                        this.selectedRowKeys = []
                    } else {
                        this.$message.success(res.data.message)
                    }
                })
            }
            if (type == 1) {
                console.log({ goodsList },'{ goodsList }')
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/tag/batch/eliminate', 'post', goodsList).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('提交成功！异步处理中')
                        this.getData()
                        this.selectedRowKeys = []
                        this.selectList = []
                    } else {
                        this.$message.success(res.data.message)
                    }
                })
            }
        },
        allOneChange(id,type) {
            if (type == 0) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/tag/batch', 'post', { goodsList:[id], tagList: ["FmaIKwYgSF"] }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('提交成功！异步处理中')
                        this.getData()
                    } else {
                        this.$message.success(res.data.message)
                    }
                })
            }
            if (type == 1) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/tag/batch/eliminate', 'post', [id]).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('提交成功！异步处理中')
                        this.getData()
                    } else {
                        this.$message.success(res.data.message)
                    }
                })
            }
        },
        handleSku() {
            if (!this.skuStr) {
                this.$message.error('请输入SKU')
                return false;
            }
            const skuArray = this.skuStr.split(',').map(Number);
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/jd/syn/sku', 'post', skuArray).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！');
                    this.skuOpen = false
                    this.getData()
                } else {
                    this.$message.error(res.data.message);
                }
            })
            // 12699287,10078838360563
            // goods/jd/pool/number统计队列数量 商品同步排队数量

        },
        // 批量同步sku
        skuChange() {
            this.skuOpen = true;
            this.skuStr = ""
        },
        // 同步当前商品
        asyncGoods(record) {
            // 100088742577
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/jd/info/' + record.jdSku, 'get').then(res => {
                console.log(res, 'resss')
                if (res.data.code == 200) {
                    this.$message.success('提交成功！异步处理中')
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
            console.log(record)
        },
        pIdChange(pId, type) {
            if (type == 0) {
                delete this.queryData.secondClassifyId;
                this.secondList = []
                delete this.queryData.tertiaryClassifyId;
                this.tertiaryList = [];
            }
            if (type == 1) {
                delete this.queryData.tertiaryClassifyId;
                this.tertiaryList = [];
            }
            this.pId = pId;
        },
        selectCode(id) {
            if (!this.queryData.classifyType) {
                this.queryData.distributorCode = null;
                this.$message.warning('请先选择小程序菜单')
                return false;
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' + this.pId + '/' + id, 'get').then(res => {
                    this.firstList = res.data.data;
                })
            }

        },
        provChange(id) {
            this.city = []
            this.queryData.cityList = []
            if (!id) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                    this.city.forEach(item => {
                        this.queryData.cityList.push(item.name)
                    })
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },

        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
            }
            this.dateValue = ''
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/es/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/jd/pool/number', 'get').then(res => {
                this.awaitNumber = res.data.data
            })


        },

        exportTask() {
            this.$message.loading('处理中')
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/exportClassifyGoodsNum', 'post', { remarks: '', classifyType: this.queryData.classifyType, distributorCode: this.queryData.distributorCode }).then(res => {
                this.$message.destroy();
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },


    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>